<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="card shadow col">
          <div class="card-header border-0">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0">
                  Спикок клиентов
                </h3>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                        :thead-classes="'thead-light'"
                        tbody-classes="list"
                        :data="users">
              <template slot="columns">
                <th>ID клиента</th>
                <th>Никнейм</th>
                <th>ФИО</th>
                <th>Организация (для юрлиц)</th>
                <th></th>
              </template>

              <template slot-scope="{row}">
                <th scope="row">
                  <div class="media align-items-center">
                    {{ row.ID }}
                  </div>
                </th>

                <td>
                  {{ row.data.user_login }}
                </td>
                <td>
                  {{ row.data.meta.first_name }} {{ row.data.meta.last_name }}
                </td>
                <td class="badges-area">
                  <span
                      v-if="Object.values(row.roles).join() === 'law-no-vat'">{{
                      row.data.meta.carrier_calc_law_org_name
                    }}</span>
                </td>
                <td class="text-right">
                  <base-dropdown class="dropdown"
                                 position="right">
                    <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false">
                      <i class="fas fa-ellipsis-v"></i>
                    </a>

                    <template>
                      <a class="dropdown-item" @click.stop="$router.push('/user-form/'+row.ID)">Изменить</a>
                      <a class="dropdown-item" @click.stop="()=>{
                        modal_client_id = row.ID
                        order_client_data = row.data.meta.first_name + ' '+  row.data.meta.last_name
                        order_modal_show = !order_modal_show
                      }">Отчёт по заказам</a>
                      <!--                <a class="dropdown-item" href="#">Another action</a>
                                      <a class="dropdown-item" href="#">Something else here</a>-->
                    </template>
                  </base-dropdown>
                </td>

              </template>

            </base-table>
          </div>
          <div class="card-footer d-flex justify-content-end">
            <base-pagination :value="filter.page"
                             @input="e => filter.page = e"
                             :page-count="page_count"
                             :per-page="filter.per_page"/>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="order_modal_show">
      <h3 slot="header" class="modal-title">Выберите период отчёта</h3>
      <div class="d-flex justify-content-between">
        <date-picker type="date"
                     valueType="format"
                     v-model="order_range"
                     range
                     :formatter="momentFormat"
                     :clearable="false"
                     lang="ru"/>
        <b-button :disabled="!order_range" variant="success" @click="getOrder">Выбрать</b-button>
      </div>
      <p v-if="empty_warning" style="color: red">На данный период нет записей</p>
    </modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import {BButton} from 'bootstrap-vue'
import 'vue2-datepicker/index.css';
import * as Excel from 'exceljs'
import saveAs from 'file-saverjs'
import moment from 'moment'

export default {
  components: {
    DatePicker,
    BButton
  },
  data() {
    return {
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY').toDate() : null
        }
      },
      users: [],
      filter: {
        page: 1,
        per_page: 10,
        role: ['fiz', 'law-no-vat']
      },
      page_count: 10,
      order_range: null,
      modal_client_id: null,
      order_modal_show: false,
      order_client_data: null,
      order_result: null,
      empty_warning: false,
    }
  },
  methods: {
    getOrder() {
      this.empty_warning = false
      const date_tranformer = (date) => {
            let splited = date.split('.').map(Number)
            return {
              day: splited[0],
              month: splited[1],
              year: splited[2]
            }
          },
          before_date = date_tranformer(this.order_range[0]),
          after_date = date_tranformer(this.order_range[1])
      this.$http.get('carriercalc/v1/report-orders', {
        params: {
          before_date: after_date,
          after_date: before_date,
          client_id: this.modal_client_id
        }
      }).then(res => {
        this.order_result = res.data.result
        if (Array.isArray(this.order_result)) {
          this.empty_warning = true
        } else {
          this.generateExcelFile()
        }
      })
    },
    async generateExcelFile() {
      let workbook = new Excel.Workbook(),
          worksheet = workbook.addWorksheet('Отчёт по клиенту ' + this.order_client_data)
      worksheet.mergeCells('A1:L1');
      worksheet.mergeCells('A2:B2');
      worksheet.mergeCells('D2:E2');
      worksheet.mergeCells('G2:H2');
      worksheet.getCell('A1').value = 'Отчёт по клиенту ' + this.order_client_data;
      worksheet.getCell('A2').value = `взаимных расчетов за период: ${this.order_range[0]} - ${this.order_range[1]}`;
      worksheet.getCell('A1').font = {
        name: 'Arial',
        family: 2,
        size: 15,
        bold: true
      };
      worksheet.getCell('A1').alignment = {horizontal: 'center'}
      worksheet.getCell('A2').alignment = {horizontal: 'center'}

      //table
      //Col width
      worksheet.getColumn('A').width = 15
      worksheet.getColumn('B').width = 15
      worksheet.getColumn('E').width = 15
      worksheet.getColumn('F').width = 15
      worksheet.getColumn('G').width = 15
      worksheet.getColumn('H').width = 15
      worksheet.getColumn('K').width = 15
      worksheet.getColumn('L').width = 15

      //data for table
      const {walk, light, heavy} = this.order_result,
          celler = (data = [], cell = 'A', offset_cell = 'B', translate) => {
            worksheet.getCell(cell + 2).value = translate
            let index = 3
            for (let p in data) {
              worksheet.getCell(cell + index).value = p + ' кг'
              worksheet.getCell(offset_cell + index).value = data[p] + ' руб'
              index++;
            }
          }
      celler(walk, 'A', 'B', 'Пешим курьером')
      celler(light, 'D', 'E', 'Легковой автомобиль')
      celler(heavy, 'G', 'H', 'Грузовой')
      const buf = await workbook.xlsx.writeBuffer()
      saveAs(new Blob([buf]), 'order.xlsx')
    },
    getUserList() {
      this.$http.get('carriercalc/v1/users-list', {
        params: this.filter
      }).then(res => {
        this.users = res.data.users
      })
    }
  },
  created() {
    this.getUserList()
  }
}
</script>
